import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <span >KAVAN Foods</span>
        <p>
          To check the live stocks <a href='https://live.kavanfoods.in/nagarbhavi'>click here</a>
        </p>
      </header>
    </div>
  );
}

export default App;
